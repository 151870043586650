import { CART_ORDER_TABS } from 'helpers/constants';
import { atom, atomFamily, selector } from 'recoil';

/**
 * @deprecated since upfront fulfillment
 */
export const priceListAtomFamily = atomFamily({
  key: 'storefront_price_list',
  default: null,
});

/**
 * @deprecated since upfront fulfillment
 */
export const orderAtomFamily = atomFamily({
  key: 'storefront_order',
  default: {
    loading: false,
    error: null,
    data: {
      id: null,
      price_list: null,
      payment: null,
      fulfillment: null,
      customer: null,
      order_entries: [],
      tax: 0.0,
      subtotal: 0.0,
      salestax_rate: 0.0,
      total: 0.0,
    },
  },
});

export const cartAtom = atom({
  key: 'storefront_cart',
  default: {
    loading: false,
    active: false, // slideover active
    order: {
      id: null,
      price_list: null,
      payment: null,
      fulfillment: null,
      customer: null,
      order_entries: [],
      tax: 0.0,
      subtotal: 0.0,
      salestax_rate: 0.0,
      total: 0.0,
    },
    priceList: {
      id: null,
    },
    pendingChanges: {},
    selectedOrderTab: 0,
  },
});

// todo: useOrder should just control this maybe
export const cartActiveSelector = selector({
  key: 'storefront_cart_active',
  get: ({ get }) => get(cartAtom).active,
  set: ({ get, set }, active) => set(cartAtom, { ...get(cartAtom), active }),
});

export const fulfillmentWizardActiveAtom = atom({
  key: 'fulfillment_wizard_active',
  default: false,
});

export const checkoutPaymentErrorsAtom = atom({
  key: 'checkout_payment_errors',
  default: [],
});

export const pendingOrderEntriesAtom = atom({
  key: 'pending_order_entries',
  default: [],
});
